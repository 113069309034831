import HtmlUtility from '../utility/HtmlUtility'
import MarketAuthorizationValidator from "../validation/MarketAuthorizationValidator";

if (
  window.location.pathname === "/third-party-disclosures" ||
  window.location.pathname === "/third-party-disclosures/"
) {

    $(function() {
      toggleFundingMethod($('input[name="funding_method"]:checked').val());
    });

    // handle next/previous buttons for form
    $(document).on("click", ".nextBtn", function() {
      let currentStep = $(this).data("step");
      let isStepValid = validateStep(currentStep);
      if (isStepValid) {
        // submit form via jQuery
        $("#third_party_disclosures").trigger('submit');
      }
    });

    function validateStep(step) {
      // Setup our Validation
      $("#third_party_disclosures").validate({
        errorElement: "div",
        errorClass: "is-invalid",
        focusInvalid: false,
        errorPlacement: function (error, element) {
            error.addClass("invalid-feedback");
            if (element.is(":radio") || element.is(":checkbox")) {
              error.appendTo(element.parent().parent());
            } else {
              error.insertAfter(element);
            }
        }
      });
      $("#third_party_disclosures").valid();

      let rules = MarketAuthorizationValidator.getRules();
      // apply the rules
      for (var key in rules) {
        $("#" + key).rules("add", rules[key]);
      };

      // returns a true or false
      return $("#third_party_disclosures").valid();
    }

    // Save Disclaimer
    $("#save_disclaimer").on("click", function (e) {
      e.preventDefault();
      HtmlUtility.html2Pdf(document.getElementById("consentText").innerHTML, "DisclosureStatement.pdf");
    });

    // Print Disclaimer
    $("#print_disclaimer").on("click", function (e) {
      e.preventDefault();
      HtmlUtility.printHtml(document.getElementById("consentText").innerHTML);
    });

    // Debit Card Related JS
    $('input[type=radio][name=funding_method]').on("change", function() {
      toggleFundingMethod(this.value)
    });

    function toggleFundingMethod(value) {
      if (value == 'CardDeposit') {
        $('.push-to-card-wpr').show();
      } else {
        $('.push-to-card-wpr').hide();
      }
    }

  }
